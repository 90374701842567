export const GENERATOR = {
  ROAD: "road",
  TAVERN: "tavern",
  WILDERNESS: "wilderness",
  DUNGEON: "dungeon",
};

export const TYPE = {
  DUNGEON: "Dungeon",
  STRONGHOLD: "Stronghold",
  URBAN: "Urban",
  CAVERN: "Cavern",
  SPECIALITY: "Speciality",
  LAIR: "Lair",
};

export const THEMES = {
  ARCHIVE: "archive",
  CLASSIC_BLANK: "classicempty",
  TOMB: "tomb",
  TOMB_BLANK: "tombempty",
  CAVE: "cavenat",
  MINE_BLANK: "mineblank",
  SEWER_BLANK_CLEAN: "seweremptyclean",
  SEWER_BLANK_TOXIC: "seweremptytoxic",
  RUINS_BLANK: "ruinsblank",
};

export const THEMES_LABEL = {
  [THEMES.ARCHIVE]: "Classic",
  [THEMES.CLASSIC_BLANK]: "Classic - Blank",
  [THEMES.TOMB]: "Tomb",
  [THEMES.TOMB_BLANK]: "Tomb - Blank",
  [THEMES.CAVE]: "Natural Cave",
  [THEMES.MINE_BLANK]: "Mine - Blank",
  [THEMES.SEWER_BLANK_CLEAN]: "Sewer Clean - Blank",
  [THEMES.SEWER_BLANK_TOXIC]: "Sewer Toxic - Blank",
  [THEMES.RUINS_BLANK]: "Ruins - Blank",
};

export const AVAILABLE_THEMES_PER_TYPE = {
  [TYPE.DUNGEON]: [
    THEMES.ARCHIVE,
    THEMES.CLASSIC_BLANK,
    THEMES.TOMB,
    THEMES.TOMB_BLANK,
    THEMES.RUINS_BLANK,
  ],
  [TYPE.STRONGHOLD]: [],
  [TYPE.URBAN]: [THEMES.SEWER_BLANK_CLEAN, THEMES.SEWER_BLANK_TOXIC],
  [TYPE.CAVERN]: [THEMES.CAVE, THEMES.MINE_BLANK],
};

export const MAP_SIZE_MAX = {
  [THEMES.ARCHIVE]: 16,
  [THEMES.CLASSIC_BLANK]: 14,
  [THEMES.TOMB]: 12,
  [THEMES.TOMB_BLANK]: 12,
  [THEMES.CAVE]: 13,
  [THEMES.SEWER_BLANK_CLEAN]: 13,
  [THEMES.SEWER_BLANK_TOXIC]: 13,
  [THEMES.MINE_BLANK]: 11,
  [THEMES.RUINS_BLANK]: 9,
};

export const AVAILABLE_TYPES_PER_GENERATOR = {
  [GENERATOR.DUNGEON]: [
    TYPE.DUNGEON,
    TYPE.CAVERN,
    // TYPE.STRONGHOLD,
    TYPE.URBAN,
    // TYPE.SPECIALITY,
  ],
  [GENERATOR.ROAD]: [],
  [GENERATOR.TAVERN]: [],
  [GENERATOR.WILDERNESS]: [],
};

export const DIFFICULTY = {
  EASY: "easy",
  MEDIUM: "medium",
  HARD: "hard",
};

export const GRID_TYPE = {
  NONE: "none",
  THIN: "thin_dark",
  THICK: "thick_dark",
  DOTTED: "dotted_dark",
  HEX: "hex_gray",
};

export const TIME = {
  DAY: "day",
  NIGHT: "night",
};

export const SEASON = {
  WINTER: "winter",
  SPRING: "spring",
  FALL: "fall",
};

export const LAYOUT_OPTIONS = [
  "random",
  "cross",
  "clustered",
  "linear",
  "branching",
  "continuous",
  "dead_ends",
];

export const STAIR_OPTIONS = ["random", "no", "yes", "many"];

export const PATREON_TIERS = {
  FREE: "FREE",
  COPPER: "COPPER",
  SILVER: "SILVER",
  GOLD: "GOLD",
  FOUNDER: "FOUNDER",
};

export const FEATURE_KEYS = {
  [GENERATOR.TAVERN]: {
    TIME_OF_DAY: "Tavern Time of Day",
    TYPE: "Tavern Type",
    SEASON: "Tavern Season",
  },
  [GENERATOR.ROAD]: {
    EVENT: "Road Event",
    TIME_OF_DAY: "Road Time of Day",
    TYPE: "Road Type",
    INCLUDE_TAVERN: "Road Include Tavern",
    SEASON: "Road Season",
  },
  [GENERATOR.WILDERNESS]: {
    TIME_OF_DAY: "Wilderness Time of Day",
    TYPE: "Wilderness Type",
    SEASON: "Wilderness Season",
  },
  [GENERATOR.DUNGEON]: {
    AVAILABLE_THEMES: "Dungeon # of Available Themes",
    VARIATIONS: "Dungeon Variations",
  },
  MAP_SIZE: "Map Size",
  DM_GUIDE: "DM Guide",
  ADVANCED_SETTINGS: "Advanced Settings",
  GRID_TYPE: "Grid Type",
  CUSTOM_NAME: "Custom Name",
  LAYOUT_OPTIONS: "Layout Options",
  ABILITY_TO_SAVE_HISTORY: "Ability to Save/History",
};

export const FEATURE_TIERS = {
  // Tavern Features
  [FEATURE_KEYS[GENERATOR.TAVERN].TIME_OF_DAY]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: true,
    [PATREON_TIERS.SILVER]: true,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS[GENERATOR.TAVERN].SEASON]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: false,
    [PATREON_TIERS.SILVER]: false,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },

  // Road Features
  [FEATURE_KEYS[GENERATOR.ROAD].EVENT]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: true,
    [PATREON_TIERS.SILVER]: true,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS[GENERATOR.ROAD].TIME_OF_DAY]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: true,
    [PATREON_TIERS.SILVER]: true,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS[GENERATOR.ROAD].TYPE]: {
    [PATREON_TIERS.FREE]: "Forest",
    [PATREON_TIERS.COPPER]: "Forest",
    [PATREON_TIERS.SILVER]: "Any",
    [PATREON_TIERS.GOLD]: "Any",
    [PATREON_TIERS.FOUNDER]: "Any",
  },
  [FEATURE_KEYS[GENERATOR.ROAD].INCLUDE_TAVERN]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: false,
    [PATREON_TIERS.SILVER]: true,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS[GENERATOR.ROAD].SEASON]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: false,
    [PATREON_TIERS.SILVER]: false,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },

  // Wilderness Features
  [FEATURE_KEYS[GENERATOR.WILDERNESS].TIME_OF_DAY]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: true,
    [PATREON_TIERS.SILVER]: true,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS[GENERATOR.WILDERNESS].TYPE]: {
    [PATREON_TIERS.FREE]: "Forest",
    [PATREON_TIERS.COPPER]: "Forest",
    [PATREON_TIERS.SILVER]: "Any",
    [PATREON_TIERS.GOLD]: "Any",
    [PATREON_TIERS.FOUNDER]: "Any",
  },
  [FEATURE_KEYS[GENERATOR.WILDERNESS].SEASON]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: false,
    [PATREON_TIERS.SILVER]: false,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },

  // Dungeon Features
  [FEATURE_KEYS[GENERATOR.DUNGEON].AVAILABLE_THEMES]: {
    [PATREON_TIERS.FREE]: [THEMES.ARCHIVE, THEMES.TOMB, THEMES.CAVE],
    // TODO: May need to change Copper when more themes are added
    [PATREON_TIERS.COPPER]: [
      THEMES.ARCHIVE,
      THEMES.TOMB,
      THEMES.CAVE,
      THEMES.SEWER_BLANK_CLEAN,
      THEMES.TOMB_BLANK,
      THEMES.MINE_BLANK,
    ],
    // TODO: Will need to limit Silver to 8 when more themes are added
    [PATREON_TIERS.SILVER]: Object.values(THEMES),
    [PATREON_TIERS.GOLD]: Object.values(THEMES),
    [PATREON_TIERS.FOUNDER]: Object.values(THEMES),
  },
  [FEATURE_KEYS[GENERATOR.DUNGEON].VARIATIONS]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: false,
    [PATREON_TIERS.SILVER]: false,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },

  // General Features
  [FEATURE_KEYS.MAP_SIZE]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: false,
    [PATREON_TIERS.SILVER]: true,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS.LAYOUT_OPTIONS]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: false,
    [PATREON_TIERS.SILVER]: true,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS.DM_GUIDE]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: false,
    [PATREON_TIERS.SILVER]: false,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS.ADVANCED_SETTINGS]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: false,
    [PATREON_TIERS.SILVER]: true,
    [PATREON_TIERS.GOLD]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS.ABILITY_TO_SAVE_HISTORY]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COPPER]: 10,
    [PATREON_TIERS.SILVER]: 20,
    [PATREON_TIERS.GOLD]: 30,
    [PATREON_TIERS.FOUNDER]: 30,
  },
};
