import React from "react";
import {
  FEATURE_KEYS,
  FEATURE_TIERS,
  GENERATOR,
  PATREON_TIERS,
} from "../../constants";
import { isAllThemeOptionsDisabled } from "../../utils";

const VisualsDropdown = ({
  options,
  optionLabels,
  value,
  onChange,
  userTier,
}) => {
  // Order of tiers, from lowest to highest
  const tierOrder = [
    PATREON_TIERS.FREE,
    PATREON_TIERS.COPPER,
    PATREON_TIERS.SILVER,
    PATREON_TIERS.GOLD,
    PATREON_TIERS.FOUNDER,
  ];

  const tierNames = {
    [PATREON_TIERS.FREE]: "Free",
    [PATREON_TIERS.COPPER]: "Copper",
    [PATREON_TIERS.SILVER]: "Silver",
    [PATREON_TIERS.GOLD]: "Gold",
    [PATREON_TIERS.FOUNDER]: "Founder",
  };

  const disabled = isAllThemeOptionsDisabled(options, userTier);

  return (
    <div>
      <select
        className="visualsDropdown"
        value={value === null ? options[0] : value}
        onChange={(e) => onChange(e.target.value)}
        style={disabled ? { opacity: 0.5 } : null}
      >
        {options.map((option, index) => {
          const availableTiers = Object.entries(
            FEATURE_TIERS[FEATURE_KEYS[GENERATOR.DUNGEON].AVAILABLE_THEMES]
          ).filter(([, availableOptions]) => availableOptions.includes(option));

          const isOptionAvailable =
            FEATURE_TIERS[FEATURE_KEYS[GENERATOR.DUNGEON].AVAILABLE_THEMES][
              userTier
            ].includes(option);

          // Find the lowest tier required for the option
          const lowestTier = availableTiers
            .map(([tier]) => tier)
            .sort((a, b) => tierOrder.indexOf(a) - tierOrder.indexOf(b))[0];

          return (
            <option
              key={index}
              value={option}
              className="visualsDropdownOption"
              title={
                !isOptionAvailable
                  ? `Available in ${tierNames[lowestTier]} Patreon Tier`
                  : undefined
              }
              disabled={!isOptionAvailable}
            >
              {optionLabels[index]}{" "}
              {!isOptionAvailable && `(${tierNames[lowestTier]} Tier)`}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default VisualsDropdown;
